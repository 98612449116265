import * as React from 'react';
import { Link, HeadFC } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import BackToHomepage from '../assets/back-to-homepage.svg';

import {
  pageStyles,
  headingStyles,
  paragraphStyles,
  imageContainer,
} from '../styles/pages/utility/styles.module.css';

const MaintenancePage = () => {
  return (
    <Layout>
      <main className={pageStyles}>
        <div className={imageContainer}>
          <StaticImage
            src="../images/Maintenance.png"
            alt="Maintenance Image"
          />
        </div>
        <h1 className={headingStyles}>Under Maintenance</h1>
        <p className={paragraphStyles}>
          The page you’re looking for is under maintenance and will be back
          soon.
        </p>
        <div>
          <Link to="/">
            <BackToHomepage />
          </Link>
        </div>
      </main>
    </Layout>
  );
};

export default MaintenancePage;

export const Head: HeadFC = () => <title>Under Maintenance</title>;
